import React from "react"
import { FormStub } from "./FormStub"
import { graphql } from "gatsby"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"

interface StubProps {
  formStub: KontentFormStubBannerFormatted
  nextBlockHasHeading: boolean
}

export const StubBanner = ({ formStub, nextBlockHasHeading }: StubProps) => {
  const { heading, button, fields } = formStub
  const marginBottomModifier = nextBlockHasHeading ? "form-stub-banner--large-margin-bottom" : ""
  return (
    <section className={`form-stub-banner ${marginBottomModifier}`}>
      <div className="fr-container">
        <h3 className="form-stub-banner__heading">{heading}</h3>
        <FormStub field={fields} button={button} />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
