import React from "react"
import { graphql } from "gatsby"
import { Banner } from "./HeroBanner-types"
import "./hero.module.scss"
import "./hero-background-video.module.scss"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "./HeroContent"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import { BackgroundVideo } from "components/background-video"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"

interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const {
    background_image,
    heading,
    sub_heading,
    buttons,
    secondary_image,
    components,
    video_background_url,
  } = banner

  const { imageBannerColorOverlay } = useColorAreaSettings()

  const bannerColorOverride = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundColor`
    : ``

  const highlights = components?.modular_content?.filter(
    component => component?.system?.type === "highlights_group"
  )[0]?.elements?.highlights?.modular_content

  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 768,
          },
        },
      })
    : {}

  const contentClassName =
    secondary_image && secondary_image?.value?.length > 0
      ? "fr-hero__banner-content-with-image"
      : "fr-hero__banner-content"

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section
      className={`fr-hero ${bannerColorOverride} ${overrideBackground}`}
      style={backgroundImageCSSVariable}
    >
      <BackgroundVideo
        hasBackgroundImage={!!background_image?.value[0]?.url}
        componentName="fr-hero"
        url={video_background_url?.value}
      >
        <div className={`${contentClassName} fr-container`}>
          <HeroContent
            heading={heading?.value}
            subHeading={sub_heading}
            buttons={buttons?.value}
            secondaryImage={secondary_image?.value[0]}
            baseClassName={contentClassName}
          >
            {highlights && (
              <div className={`hero-banner-content__highlights`}>
                <HeroHighlights highlights={highlights} />
              </div>
            )}

            {video_background_url?.value && <BackgroundVideoPlayButton />}
          </HeroContent>
        </div>
      </BackgroundVideo>
    </section>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
          ...HighlightGroupFragment
          ...CouponFragment
          ...LogoSliderFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
      video_background_url {
        value
      }
    }
  }
`
