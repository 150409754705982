import { graphql } from "gatsby"
import React from "react"
import { GoAlert } from "react-icons/go"
import * as styles from "./alert.module.scss"

interface AlertProps {
  iconCodeName?: string
  title: string
  url: string
  backgroundColor?: string
}

export const AlertBanner = ({ title, url, iconCodeName = "", backgroundColor = "" }: AlertProps) => {
  const getIcon = (codename: string) => {
    switch (codename) {
      case "alert":
        return <GoAlert />
      default:
        break
    }
  }
  return (
    <div className={styles.emergencyTicker}>
      <div className={styles.marquee}>
        {iconCodeName && getIcon(iconCodeName)}
        <a href={url}>{title}</a>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment AlertBannerFragment on kontent_item_modular_content_element_value {
    value {
      ... on kontent_item_alert_banner {
        id
        elements {
          alert_text {
            value
          }
          background_color {
            value
          }
          icon {
            value {
              codename
            }
          }
          link {
            value
          }
          date{
            value: value(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`