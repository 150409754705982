import React, { useContext } from "react"
import "./form-stub.module.scss"
import { FormFieldsContext } from "../../contexts/FormFieldsContext"
import { KontentItemFormFieldElements } from "../forms/formBuilder-types"
import { FormStubBannerButtonValue } from "./formStubBanner-types"
import { AnchorLink } from "components/anchor-link/AnchorLink"

interface FormStubProps {
  button: FormStubBannerButtonValue
  field: KontentItemFormFieldElements
}

export const FormStub = ({ button, field }: FormStubProps) => {
  const { button_text, linked_page, anchor_reference } = button
  const slug = linked_page?.value[0].elements?.slug?.value

  const { field_type, form_field, label } = field

  const { setEmailAddress } = useContext(FormFieldsContext)
  const setFields = (e: { target: HTMLInputElement }) => {
    const { value } = e?.target
    setEmailAddress(value)
  }

  return (
    <div className="form-stub">
      <div className="form-stub__input">
        <label htmlFor={form_field?.value[0]?.codename}>{label?.value}</label>

        <input
          type={field_type?.value[0].codename}
          name={form_field?.value[0].codename}
          placeholder={
            field_type?.value[0].codename === "email"
              ? "email@example.com"
              : "John Smith"
          }
          onBlur={setFields}
        />
      </div>
      {slug && (
        <AnchorLink
          children={button_text.value}
          to={
            slug === "/"
              ? `${slug}#${anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'}`
              : `/${slug}#${anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'}`
          }
          className="button--solid-accent form-stub__button"
        />
      )}
    </div>
  )
}
